import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import '../../../css/Style/Header/BlackHeader.css'
import BackDrop from '../SideMenu/BackDrop'
import SideMenu from '../SideMenu/SideMenu'
import BookOfTrips from '../../images/LandingPage/logo/bot_logo.webp'
import axios from 'axios'
import {BASE_URL} from "../../pages/constant";
import api from "../../pages/api";
import {getAllCategoryData} from "../../util/GetAllCategory";

const Header = () => {
    const [navbar, setNavbar] = useState(false);
    const [sideMenu, setSidemenu] = useState(false);
    const [domain, setDomain] = useState(false);
    const [allCategory, setAllCategory] = useState(false);

    // get all category
    const getAllCategory = async () => {
        const host = BASE_URL;
        await getAllCategoryData(BASE_URL).then((res) => {
            if (res) {
                setAllCategory(res)
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const NavbarBgChange = () => {
        if (window.scrollY >= 60) {
            setNavbar(true)
            setDomain(true)
        } else {
            setNavbar(false)
            setDomain(false)
        }
    }
    window.addEventListener('scroll', NavbarBgChange)

    const toggleMenu = () => {
        setSidemenu(!sideMenu)
    }

    useEffect(() => {
        getAllCategory();
    }, [])

    function getNextFourMonthsShortForm() {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth(); // Get the current month (0-11)

        const nextFourMonths = [];

        for (let i = 0; i <= 3; i++) {
            const nextMonthIndex = (currentMonth + i) % 12; // Ensure the month index wraps around correctly
            nextFourMonths.push(monthNames[nextMonthIndex]);
        }

        return nextFourMonths;
    }

    const nextMonths = getNextFourMonthsShortForm();

    function getFullMonthName(shortForm) {
        const monthMap = {
            Jan: "January",
            Feb: "February",
            Mar: "March",
            Apr: "April",
            May: "May",
            Jun: "June",
            Jul: "July",
            Aug: "August",
            Sep: "September",
            Oct: "October",
            Nov: "November",
            Dec: "December"
        };

        // Convert the short form to Title Case to match the keys in the map
        let titleCaseShortForm = shortForm
        try {
            titleCaseShortForm = shortForm.charAt(0).toUpperCase() + shortForm.slice(1).toLowerCase();
        } catch (error) {
            return shortForm
        }

        return monthMap[titleCaseShortForm] || titleCaseShortForm
    }

    return (
        <>
            <BackDrop sideMenu={sideMenu} closeSideMenu={toggleMenu} />
            <SideMenu sideMenu={sideMenu} setSidemenu={setSidemenu} />
            <nav className={`black navbar ${navbar ? "active" : ""} fixed-top`}>
                <Link to='/'>
                    <div className="logo d-flex align-items-center">
                        {
                            domain ?
                                <h6 className='text-white m-0'><b>bookoftrips.in</b></h6>
                                :
                                <img width={60} src={BookOfTrips} alt="" />
                        }
                    </div>
                </Link>
                <div className="contact_number"><span><i className='me-2 fa-solid fa-phone'></i>+917015415229</span></div>
                <div className="menu_list d-none d-lg-block">
                    <ul className='d-flex'>
                        {
                            nextMonths && nextMonths?.map((obj) => {
                                return <li key={obj}>
                                    <Link to={`/${obj.toLowerCase().replace(/\s+/g, "-")}`}>{getFullMonthName(obj)}</Link>
                                </li>
                            }).slice(nextMonths.length - 4, nextMonths.length)
                        }
                        <li>
                            <Link to='/about'>ABOUT US</Link>
                        </li>
                        <li>
                            <Link to='/contact'>CONTACT US</Link>
                        </li>
                    </ul>
                </div>
                <div className="nav_toggle_button d-lg-none" onClick={toggleMenu}>
                    {
                        sideMenu ?
                            <i className="fa-solid fa-xmark"></i>
                            :
                            <i className="fa-solid fa-bars"></i>
                    }
                </div>
            </nav>
        </>

    )
}

export default Header
