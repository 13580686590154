import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import api from "../api";
import {BASE_URL} from "../constant";

const ProtectedRoute = (props) => {
    const params = useParams()
    const navigate = useNavigate();
    const [isLogin, setIsLogin] = useState(false);

    useEffect(()=>{
        // api(BASE_URL).get(
        // axios({
        //     method: "get",
        //     // withCredentials: true,
        //     url: `book_of_trips/users/isLogin`,
        // })
        api(BASE_URL).get(
            `book_of_trips/users/isLogin`
        )
            .then((res)=>{
            setIsLogin(true)
            if(res.data.success){
                if(props.adminDashboard){
                    navigate('/admin-dashboard');
                }
                if(props.addTrip){
                    navigate('/add-trip');
                }
                if(props.editTrip === 'editTrip'){
                    navigate(`/edit-trip/${params.id}`);
                }
                if(props.allAdminTrips){
                    navigate('/all-trips');
                }
                if(props.inquiry){
                    navigate('/customer-inquiry');
                }
                if(props.banner){
                    navigate('/promotional-banner');
                }
                if(props.homeBanner){
                    navigate('/home-banner');
                }
                if(props.addCityImage){
                    navigate('/add-city-image');
                }
                // if(props.addCouponCode){
                //     navigate('/add-coupon-code');
                // }
                if(props.support){
                    navigate('/cutomer-support');
                }
                if(props.bookingList){
                    navigate('/booking-list');
                }
                if(props.homeCategory){
                    navigate('/add-home-category');
                }
                if(props.addCategory){
                    navigate('/add-category');
                }
                // if(props.adminCareer){
                //     navigate('/admin-career');
                // }
                // if(props.adminBlog){
                //     navigate('/admin-blog');
                // }
                if(props.application){
                    navigate('/job-application');
                }
            }
        }).catch((err)=>{
            setIsLogin(false)
            navigate('/login')
            console.log(err);
        }); 
        // eslint-disable-next-line
    }, [])

  return (
    <>
    {isLogin && 
    <div>
        {props.children}
    </div>
    }
    </>
  )
}

export default ProtectedRoute
