import { message } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Layout from '../../component/Admin/AdminLayout';
import AdminHeader from './component/AdminHeader';
import {BASE_URL} from "../constant";
import api from "../api";
// import noresult from '../../images/noresult.png';

const AdminCareer = () => {
    const [loading, setLoading] = useState(null);
    const [career, setCareer] = useState(null);
    const [id, setId] = useState(null);
    const [data, setData] = useState(null);
    const [responsibility, setResponsibility] = useState(null);
    const [requirement, setRequirement] = useState(null);
    const [inputField, setInputField] = useState({
        title: null,
        time: null,
        location: null,
        role: null,
        address: null,
        experience: null,
        salary: null,
        responsibility: null,
        requirement: null,
        skills: null,
    });
    const navigate = useNavigate();
    const params = useParams();

    const getAllCareer = async (elm) => {
        const host = BASE_URL;
        await api(host)
            .get(`book_of_trips/career/get-all-career`).then((res) => {
            let arr = res.data.data
            setCareer(arr.reverse())
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        if (career === null) {
            getAllCareer();
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(params.title){
            const singleCareerData = career?.filter((career)=>{
                return career.id === id
            })
            if(singleCareerData){
                setData(singleCareerData[0]);
                setResponsibility(singleCareerData[0]?.responsibility.split('\n'));
                setRequirement(singleCareerData[0]?.requirement.split('\n'));
            }
        }
        // eslint-disable-next-line
    }, [id]);

    const submit = (e) => {
        e.preventDefault()
        setLoading(true)
        axios({
            method: "POST",
            data: {
                ...inputField
            },
            withCredentials: true,
            url: `book_of_trips/career/add-career`,
        }).then((res) => {
            setInputField({
                title: null,
                time: null,
                location: null,
                role: null,
                address: null,
                experience: null,
                salary: null,
                responsibility: null,
                requirement: null,
                skills: null,
            })
            getAllCareer()
            setTimeout(() => {
                setLoading(false)
            }, 1500);
            message.success(res.data.message)
        }).catch((err) => {
            setTimeout(() => {
                setLoading(false)
            }, 1500);
            message.error(err.response.data.message)
            console.log(err);
        });
    }

    const deleteCareer = (id) => {
        axios({
            method: "POST",
            data: {
                id: id
            },
            withCredentials: true,
            url: `book_of_trips/career/delete-career`,
        }).then((res) => {
            getAllCareer()
            setTimeout(() => {
                setLoading(false)
            }, 1500);
            message.success(res.data.message)
        }).catch((err) => {
            setTimeout(() => {
                setLoading(false)
            }, 1500);
            console.log(err);
        });
    }

    const hanleChange = (event) => {
        setInputField({ ...inputField, [event.target.name]: event.target.value })
    }

    return (
        <>
        { params.title?
        <div>
             <Layout>
                <AdminHeader title={`${params?.title}`} redirect='/admin-career' btnName='Application' btnUrl='/job-application' />
                <div className=" Mon_font px-3 m-0 py-4 shd border mx-4">
                    <div className='d-flex justify-content-between align-items-center'>
                        <h4 className='fw-bold'>{data?.title}</h4>
                        <div className='border'>
                            <h6 className='fw-bold p-2 border bg-warning'>{data?.time}</h6>
                            <h6 className='text-center'>{
                                 new Date(data?.createdAt).toLocaleString('default', {day:'numeric'}) + " " + new Date(data?.createdAt).toLocaleString('default', {month:'long'})
                            }</h6>
                        </div>
                    </div>
                    <h5 className='fs-6'>{data?.location}</h5>
                </div>

                <div className=" Mon_font m-0 py-4 shd border mx-4 px-0 mt-4">
                    <div className=''>
                        <h5 className='px-3 fw-bold fs-6'>Role: <span className='fw-normal fs-6'>{data?.role}</span></h5>
                        <hr />
                    </div>
                    <div className=''>
                        <h5 className='px-3 fw-bold fs-6'>Skills: <span className='fw-normal fs-6'>{data?.skills}</span></h5>
                        <hr />
                    </div>
                    <div className=''>
                        <h5 className='px-3 fw-bold fs-6'>Address: <span className='fw-normal fs-6'>{data?.address}</span></h5>
                        <hr />
                    </div>
                    <div className=''>
                        <h5 className='px-3 fw-bold fs-6'>Experience: <span className='fw-normal fs-6'>{data?.experience}</span></h5>
                        <hr />
                    </div>
                    <div className=''>
                        <h5 className='px-3 fw-bold fs-6'>Salary: <span className='fw-normal fs-6'>{data?.salary}</span></h5>
                    </div>
                </div>
                <div className=" Mon_font px-0 m-0 py-4 shd border mx-4 mt-4">
                    <div className=''>
                        <h5 className='px-3 fw-bold fs-6'>Responsibilities</h5>
                        <hr />
                    </div>
                    {responsibility?.map((data)=>{
                        return (
                            <>
                            <div key={data}>
                                <h5 className='px-3 fw-bold fs-6'><span className='fw-normal fs-6'>- {data}</span></h5>
                            </div>
                            </>
                        )
                    })}
                </div>
                <div className=" Mon_font px-0 m-0 py-4 shd border mx-4 mt-4 mb-4">
                    <div className=''>
                        <h5 className='px-3 fw-bold fs-6'>Requirement</h5>
                        <hr />
                    </div>
                    {requirement?.map((data)=>{
                        return (
                            <>
                            <div key={data}>
                                <h5 className='px-3 fw-bold fs-6'><span className='fw-normal fs-6'>- {data}</span></h5>
                            </div>
                            </>
                        )
                    })}
                </div>
            </Layout>
        </div>
        :
        <div>
            <Layout>
                <AdminHeader title='Career' redirect='/admin-dashboard' btnName='Application' btnUrl='/job-application' />
                <div className=" Mon_font px-3 m-0 pb-4">
                    <form className="row g-3 m-0 px-2 py-3 shd border border-2" style={{ backgroundColor: '#f5f5f5' }} onSubmit={submit}>
                        <div className='col-10 m-0 '>
                            <input className='form-control' type="text" name='title' required placeholder='Title' onChange={hanleChange} />
                        </div>
                        <div className='col-2 m-0'>
                            <select className='form-control' type="text" name='time' required placeholder='job heading' onChange={hanleChange} style={{cursor: 'pointer'}}>
                                <option className='form-control' value="Half Time">Half Time</option>
                                <option className='form-control' value="Full Time">Full Time</option>
                            </select>
                        </div>
                        <div className='col-4 '>
                            <input className='form-control' type="text" name='location' required placeholder='Location' onChange={hanleChange}/>
                        </div>
                        <div className='col-4'>
                            <input className='form-control' type="text" name='role' required placeholder='Role' onChange={hanleChange} />
                        </div>
                        <div className='col-4'>
                            <input className='form-control' type="text" name='experience' required placeholder='Experience' onChange={hanleChange} />
                        </div>
                        <div className='col-8'>
                            <input className='form-control' type="text" name='address' required placeholder='Address' onChange={hanleChange} />
                        </div>
                        <div className='col-4'>
                            <input className='form-control' type="text" name='salary' required placeholder='Salary' onChange={hanleChange} />
                        </div>
                        <div className='col-12'>
                            <input className='form-control' type="text" name='skills' required placeholder='Skills' onChange={hanleChange} />
                        </div>
                        <div className='col-6'>
                            <textarea className='form-control' type="text" name='responsibility' required placeholder='Responsibility' onChange={hanleChange} />
                        </div>
                        <div className='col-6'>
                            <textarea className='form-control' type="text" name='requirement' required placeholder='Requirement' onChange={hanleChange} />
                        </div>
                        <div className='col-12'>
                            <button type='submit' className='btn btn-warning text-center w-100'>{loading ? 'Loading...' : 'Save'}</button>
                        </div>
                    </form>
                    {career?.length === 0 ?
                        <div className='d-flex flex-column justify-content-center align-items-center mt-5'>
                            {/*<img src={noresult} alt="" width={150} />*/}
                            <h3>No Record Found</h3>
                        </div> :
                        <div className='w-100 mt-5 Mon_font'>
                            <div className="row p-4 mt-3">
                                {
                                    career && career?.map(career => {
                                        const date = new Date(career?.createdAt)
                                        const month = date.toLocaleString('default', {month:'long'})
                                        const day = date.toLocaleString('default', {day:'numeric'})
                                        return (
                                        <>
                                            <div key={career?.id} className="mb-3 col-12 col-sm-12 col-md-6 col-lg-4">
                                                <div className="card border-0" onClick={()=>{navigate(`/admin-career/${career.title}`);  setId(career.id)}} style={{borderRadius: '20px', boxShadow: '3px 3px 10px 0px rgba(0, 0, 0, 0.2)', cursor: 'pointer'}}>
                                                    <div className="p-3">
                                                        <p className='p-0 m-0 fw-bold fs-5'>{career.title}</p>
                                                        <p className='p-0 m-0 mt-2 fs-6'>{career.location}</p>
                                                    </div>
                                                    <div className="card-body d-flex justify-content-between mt-4">
                                                        <p className='m-0 bg-warning d-flex align-items-center px-2' style={{fontSize: '13px', borderRadius: '5px', fontWeight: 400}}>{career.time}</p>
                                                        <Link to='#' className='text-white px-2 py-1 bg-danger rounded' onClick={(e)=>{
                                                                deleteCareer(career.id);
                                                                e.stopPropagation()
                                                            }}>Delete</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        )
                                    })
                                }
                            </div>
                        </div>}
                </div>
            </Layout>
        </div>
        }
        </>
    )
}

export default AdminCareer
