import React, { useState } from "react";
// import direction from "../../images/Contact/direction.svg";
// import experts from "../../images/Contact/experts.svg";
// import nospam from "../../images/Contact/no-spam.svg";
import "../../../css/Style/Contact/Contact.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { message } from "antd";
import {BASE_URL} from "../../pages/constant";
import api from "../../pages/api";

const Form = ({ setExpertForm }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({});
  const location = useLocation();

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    // axios({
    //   method: "POST",
    //   data: {
    //     name: form.name,
    //     phone: form.phone,
    //     email: form.email,
    //     message: form.message,
    //   },
    //   withCredentials: true,
    //   url: `book_of_trips/inquiry/add-inquiry`,
    // })
    api(BASE_URL).post(`book_of_trips/inquiry/add-inquiry`, {
      name: form.name,
      phone: form.phone,
      email: form.email,
      message: form.message,
    })
      .then((res) => {
        setTimeout(() => {
          setLoading(false);
        }, 1500);
        if (res.data.success) {
          message.success(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  return (
    <div className="contact__form container rounded my-lg-5 my-3">
      <div className="row">
        {location.pathname !== "/contact" && (
          <i
            onClick={() => setExpertForm(false)}
            className="form__icon text-danger text-center fa-solid fa-xmark"
          ></i>
        )}
        <div
          className={`${
            location.pathname === "/contact"
              ? "col-12 col-sm-6 col-md-6 col-lg-6 Mon_font px-lg-5 px-sm-2"
              : "col-12 col-sm-12 col-md-12 col-lg-12 px-lg-5 px-sm-2 py-lg-3"
          }`}
        >
          <h3 className="form__heading text-center fw-bold my-3">
            Want us to help you better?
            <br />
            <span className="text-warning">Connect with us </span>Right Away!
          </h3>
          <hr className="px-5 mx-4" />
          <form
            className="px-5"
            style={{ backgroundColor: "#fff" }}
            onSubmit={submitHandler}
          >
            <div className="mb-1 mb-lg-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                required
                name="name"
                onChange={handleChange}
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                maxLength={50}
              />
            </div>
            <div className="mb-1 mb-lg-3">
              <label htmlFor="phone" className="form-label">
                Phone Number
              </label>
              <input
                required
                name="phone"
                onChange={handleChange}
                type="number"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                maxLength={10}
              />
            </div>
            <div className="mb-1 mb-lg-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                required
                name="email"
                onChange={handleChange}
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>
            <div className="mb-1 mb-lg-3">
              <label htmlFor="message" className="form-label">
                Message
              </label>
              <input
                required
                name="message"
                onChange={handleChange}
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                maxLength={299}
              />
            </div>
            <div className="text-center my-2">
              <button type="submit" className="btn btn-success">
                {loading ? "Loading..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
        {location.pathname === "/register" && (
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 Mon_font p-4 pt-2">
            <h3 className="form__heading mt-lg-5 mt-2 text-warning fw-bold text-center">
              Free Consultation by our Travel Experts{" "}
              <br className="d-none d-lg-block" />
              Just a Click away!
            </h3>
            <div className="form__img text-secondary fw-light row text-center mt-5">
              <div className="col-lg-6 col-4 col-sm-4 col-md-4">
                {/*<img src={experts} alt="" />*/}
                <p>Free consultation by Experts</p>
              </div>
              <div className="col-lg-6 col-4 col-sm-4 col-md-4">
                {/*<img src={direction} alt="" />*/}
                <p>No spam Calls/No data sharing</p>
              </div>
              <div className="col-lg-12 col-4 col-sm-4 col-md-4">
                {/*<img src={nospam} alt="" />*/}
                <p>Only Authentic Destination</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Form;
