import React, { useEffect, useState } from 'react';
import OtherLayout from '../component/Layout/OtherLayout';
// import contact from '../images/LandingPage/bg/contact.jpeg';
import '../../css/Style/Contact/Contact.css';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { message } from 'antd';
import map from '../images/Contact/map.png';
import OfferAndUpdates from '../component/LandingPage/OfferAndUpdates';
import api from "./api";
import {BASE_URL} from "./constant";

const Contact = ({setExpertForm}) => {
    const [loading, setLoading] = useState(false);
    document.title = `BookOfTrips - ${'Contact Us'}`
    const location = useLocation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [form, setForm] = useState({})
        // {
        //     firstName:'',
        //     lastName:'',
        //     phone:'',
        //     email:'',
        //     message:''
        // })

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const submitHandler = async (e) => {
        e.preventDefault()
        setLoading(true)
        // axios({
        //     method: "POST",
        //     data: {
        //         name: form.firstName + ' ' + form.lastName,
        //         phone: form.phone,
        //         email: form.email,
        //         message: form.message,
        //     },
        //     withCredentials: true,
        //     url: `book_of_trips/inquiry/add-inquiry`,
        // })
        api(BASE_URL).post(`book_of_trips/inquiry/add-inquiry`, {
            name: form.firstName + ' ' + form.lastName,
            phone: form.phone,
            email: form.email,
            message: form.message,
        }).then((res) => {
            setTimeout(() => {
                setLoading(false)
            }, 1500);
            setForm({
                firstName:'',
                lastName:'',
                phone:'',
                email:'',
                message:''
            })
            if (res.data.success) {
                message.success(res.data.message)
            }
        }).catch((err) => {
            setLoading(false)
            console.log(err);
        });
    }

    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
    return (
        <>
            {/*<OtherLayout background={contact} title="Contact Us" btn1='Call now' btn2='Whatsapp' btn3='Send Enquiry'>*/}
                <OtherLayout title="Contact Us" btn1='Call now' btn2='Whatsapp' btn3='Send Enquiry'>

                {/* <Form /> */}
            <div className="contact__form container rounded my-lg-3 my-3">
                <div className="row justify-content-center align-items-center">
                    {
                        location.pathname !== '/contact' && <i onClick={() => setExpertForm(false)} className='form__icon text-danger text-center fa-solid fa-xmark'></i>
                    }
                    <div className='col-12 col-sm-6 col-md-6 col-lg-6 Mon_font p-4 pt-2 text-center'>
                        <img width={windowWidth < 576? 350 : 750} src={map} alt="" style={{margin: `0 0 0 ${windowWidth < 576? '0px' : '-100px'}`}}/>
                    </div>
                    <div className={`Mon_font ${location.pathname === "/contact" ? "col-12 col-sm-6 col-md-6 col-lg-6 Mon_font px-lg-5 px-sm-2" : "col-12 col-sm-12 col-md-12 col-lg-12 px-lg-5 px-sm-2 py-lg-3"}`}>
                        <h3 className={`form__heading my-3 ${windowWidth < 576? 'text-center' : null}`} style={{lineHeight: `${windowWidth < 576? '2.2' : '1.3'}`}}>NOT SURE WHAT TO DO?<br />WE'LL GIVE YOU A <strong>CALL BACK</strong></h3>
                        
                        <form className="row Mon_font mt-5" style={{ backgroundColor: "#fff" }} onSubmit={submitHandler}>
                            <div className="col-6 mb-1 mb-lg-3 contact-form-input">
                                <label htmlFor="contact-first-name" className="form-label contact-form-lable">First Name</label>
                                <input className='w-100 my-2' value={form.firstName} required name='firstName' onChange={handleChange} type="text"  id="contact-first-name" aria-describedby="emailHelp" />
                            </div>
                            <div className="col-6 mb-1 mb-lg-3 contact-form-input">
                                <label htmlFor="contact-last-name" className="form-label contact-form-lable">Last Name</label>
                                <input className='w-100 my-2' value={form.lastName} required name='lastName' onChange={handleChange} type="text"  id="contact-last-name" aria-describedby="emailHelp" />
                            </div>
                            <div className="col-12 mb-1 mb-lg-3 contact-form-input">
                                <label htmlFor="contact-phone" className="form-label contact-form-lable">Phone Number</label>
                                <input className='w-100 my-2'  value={form.phone} required name='phone' onChange={handleChange} type="text" id="contact-phone" aria-describedby="emailHelp" minLength={10} maxLength={10} />
                            </div>
                            <div className="col-12 mb-1 mb-lg-3 contact-form-input">
                                <label htmlFor="contact-email" className="form-label contact-form-lable">Email</label>
                                <input className='w-100 my-2' value={form.email} required name='email' onChange={handleChange} type="email"  id="contact-email" aria-describedby="emailHelp" />
                            </div>
                            <div className="col-12 mb-1 mb-lg-3 contact-form-input">
                                <label htmlFor="contact-message" className="form-label contact-form-lable">Message</label>
                                <textarea className='w-100 my-2' rows={5} value={form.message} required name='message' onChange={handleChange} type="text"  id="contact-message" aria-describedby="emailHelp" maxLength={299} />
                            </div>
                            <div className={`my-2 ${windowWidth < 576 && 'text-center'}`}>
                                <button to='#' type='submit' className='border-0 shd px-4 py-2 contact-submit-button rounded-pill text-warning'>{loading ? 'Loading...' : 'Submit'}</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div>
                    <div className='Mon_font text-center my-5'>
                        <p className='fw-bold fs-6'>CONTACT NUMBER: +91 7015415229, <br /> +91 7015415229</p>
                        <span className='fw-bold fs-6'>ADDRESS: </span> <span className='fs-6'>BookOfTrips 38/1 double storey, Tilak Nagar New Delhi 110018</span>
                    </div>
                </div>
            </div>
            <OfferAndUpdates />
            </OtherLayout>
        </>
    )
}

export default Contact
