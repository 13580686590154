import './App.css';
import LandingPage from './static/js/pages/LandingPage';
import About from './static/js/pages/About'
import Contact from './static/js/pages/Contact';
import Blog from './static/js/pages/Blog'
import SingleTrip from './static/js/pages/SingleTrip';
import MonthCategoryListPage from './static/js/pages/MonthCategoryListPage';

// import BookingPage from './static/js/pages/BookingPage';
import Payment from './static/js/pages/Payment'
import ScrollToTop from './static/js/component/ScrollToTop';
import {BrowserRouter, Route, Routes, useParams} from 'react-router-dom';
import ProtectedRoute from './static/js/pages/admin/ProtectedRoute';
import AdminDashboard from './static/js/pages/admin/AdminDashboard';
import AdminLogin from './static/js/pages/admin/AdminLogin';
import AddTrip from './static/js/pages/admin/AddTrip';
import CategoryListPage from './static/js/pages/CategoryListPage';
import Spinner from './static/js/component/Spinner';
import { SetTrip } from './static/js/redux/features/tripSlice';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useEffect, useState } from 'react';
import AllAdminTrips from './static/js/pages/admin/AllAdminTrips';
import Inquiry from './static/js/pages/admin/Inquiry';
import Banner from './static/js/pages/admin/Banner';
import HomeBanner from './static/js/pages/admin/HomeBanner';
import Support from './static/js/pages/admin/Support';
import AddCityImage from './static/js/pages/admin/AddCityImage';
import PaymentPage from './static/js/pages/payment/PaymentPage';
import Success from './static/js/pages/payment/Success';
import Failure from './static/js/pages/payment/Failure';
import TermsPage from './static/js/pages/TermsPage';
import Privacy from './static/js/pages/Privacy';
import Refund from './static/js/pages/Refund';
import BookingList from './static/js/pages/admin/BookingList';
import BookingTrip from './static/js/pages/admin/BookingTrip';
import EditTrip from './static/js/pages/admin/EditTrip';
import WebsiteUpdate from './static/js/pages/admin/component/WebsiteUpdate';
import Career from './static/js/pages/Career';
import HomeCategory from './static/js/pages/admin/HomeCategory';
import AddCategory from './static/js/pages/admin/AddCategory';
import AdminCareer from './static/js/pages/admin/AdminCareer';
import Application from './static/js/pages/admin/Application';
import AddPdf from './static/js/pages/admin/AddPdf';
import AdminBlog from './static/js/pages/admin/AdminBlog';
import CustomisedListPage from './static/js/pages/CustomisedListPage';
// import AddCouponCode from './static/js/pages/admin/AddCouponCode';
import CategoryBlogPage from './static/js/pages/CategoryBlogPage';
import PhonePeIntegration from './static/js/component/PhonePeIntegration';
import {BASE_URL} from "./static/js/pages/constant";
import api from "./static/js/pages/api";
import {getAllTrips} from "./static/js/util/FetchTrips";

function App() {
  // const params = useParams();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.loader);
  const [website, setWebsite] = useState(null);

  const AllTrips = async () => {
    await
        getAllTrips(BASE_URL).then((res) => {
      dispatch(SetTrip(res));
    }).catch((err) => {
      console.log(err);
    });
  }

  const getWebsiteDetails = () => {
    // axios({
    //   method: "get",
    //   withCredentials: true,
    //   url: `api/website/get-website`,
    // }).then((res) => {
    //   setWebsite(res.data.data)
    // }).catch((err) => {
    //   console.log(err);
    // });

    api(BASE_URL)
        .get(`book_of_trips/website/get-website`)
        .then((res) => {
      setWebsite(res.data.data)
    }).catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {
    if (website === null) {
      getWebsiteDetails()
    }
    AllTrips();
    // eslint-disable-next-line
  }, [])

  function SingleTripRouter() {
    const { id } = useParams();

    // Array of strings for full and short month names with different cases
    const monthCategories = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December",
      "jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec",
      "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
      "january", "february", "march", "april", "may", "june",
      "july", "august", "september", "october", "november", "december",
    ];

    // Check if id exists in monthCategories array
    if (monthCategories.includes(id)) {
      return <MonthCategoryListPage />;
    }

    // Default rendering for other ids
    return <SingleTrip />;
  }


  return (
    <BrowserRouter>
      <ScrollToTop />
      {
        loading ?
          <Spinner />
          :
          <main>
            <Routes>
              {/* admin routes */}
              <Route exact path='/admin-dashboard' element={<ProtectedRoute adminDashboard='adminDashboard'><AdminDashboard /></ProtectedRoute>} />
              <Route exact path='/login' element={<AdminLogin />} />
              <Route exact path='/add-trip' element={<ProtectedRoute addTrip={true}><AddTrip /></ProtectedRoute>} />
              <Route exact path='/admin-career' element={<ProtectedRoute adminCareer={true}><AdminCareer /></ProtectedRoute>} />
              <Route exact path='/admin-career/:title' element={<ProtectedRoute adminCareer={true}><AdminCareer /></ProtectedRoute>} />
              <Route exact path='/admin-blog' element={<ProtectedRoute adminBlog={true}><AdminBlog /></ProtectedRoute>} />
              <Route exact path='/job-application' element={<ProtectedRoute application={true}><Application /></ProtectedRoute>} />
              <Route exact path='/edit-trip/:id' element={<ProtectedRoute editTrip={true}><EditTrip /></ProtectedRoute>} />
              <Route exact path='/all-trips' element={<ProtectedRoute allAdminTrips={true}><AllAdminTrips /></ProtectedRoute>} />
              <Route exact path='/customer-inquiry' element={<ProtectedRoute inquiry={true}><Inquiry /></ProtectedRoute>} />
              <Route exact path='/promotional-banner' element={<ProtectedRoute banner={true}><Banner /></ProtectedRoute>} />
              <Route exact path='/home-banner' element={<ProtectedRoute homeBanner={true}><HomeBanner /></ProtectedRoute>} />
              <Route exact path='/add-city-image' element={<ProtectedRoute addCityImage={true}><AddCityImage /></ProtectedRoute>} />
              {/*<Route exact path='/add-coupon-code' element={<ProtectedRoute addCouponCode={true}><AddCouponCode /></ProtectedRoute>} />*/}
              <Route exact path='/add-home-category' element={<ProtectedRoute homeCategory={true}><HomeCategory /></ProtectedRoute>} />
              <Route exact path='/add-category' element={<ProtectedRoute addCategory={true}><AddCategory /></ProtectedRoute>} />
              <Route exact path='/cutomer-support' element={<ProtectedRoute support={true}><Support /></ProtectedRoute>} />
              <Route exact path='/booking-list' element={<ProtectedRoute bookingList={true}><BookingList /></ProtectedRoute>} />
              <Route exact path='/booking-trip/:id' element={<ProtectedRoute bookingTrip={true}><BookingTrip /></ProtectedRoute>} />
              <Route exact path='/check-connection' element={<WebsiteUpdate />} />
              <Route exact path='/add-pdf' element={<AddPdf />} />
              {/* end admin routes */}

              {/* payment route */}
              {/*<Route exact path='/payment-page' element={<PaymentPage />} />*/}
              {/* end payment route */}

              {/* customer routes */}
              <Route exact path='/' element={<LandingPage />} />
              <Route exact path='/about' element={<About />} />
              <Route exact path='/contact' element={<Contact />} />
              {/*<Route exact path='/career' element={<Career />} />*/}
              {/*<Route exact path='/career/:title' element={<Career />} />*/}
              {/*<Route exact path='/blog' element={<Blog />} />*/}
              {/*<Route exact path='/blog/:category' element={<CategoryBlogPage />} />*/}
              {/*<Route exact path='/Trending-Blogs/:title' element={<CategoryBlogPage />} />*/}
              {/*<Route exact path='/Latest-Blogs/:title' element={<CategoryBlogPage />} />*/}
              {/*<Route exact path='/Informational-Blogs/:title' element={<CategoryBlogPage />} />*/}
              <Route exact path='/category/:category' element={<CategoryListPage />} />
              <Route exact path='/customised/:category/' element={<CustomisedListPage />} />
              <Route exact path='/:id' element={<SingleTripRouter />} />
              <Route exact path='/book/:id' element={<SingleTrip />} />
              {/*<Route exact path='/payment' element={<Payment />} />*/}
              {/*<Route exact path='/success/:id' element={<Success />} />*/}
              {/*<Route exact path='/failure/:id' element={<Failure />} />*/}
              <Route exact path='/terms-and-conditions' element={<TermsPage />} />
              <Route exact path='/privacy-policy' element={<Privacy />} />
              <Route exact path='/refund-policy' element={<Refund />} />
              {/*<Route exact path='/phonepe' element={<PhonePeIntegration/>} />*/}
              {/* end customer routes */}
            </Routes>
          </main>
      }
    </BrowserRouter>
  );
}

export default App;
