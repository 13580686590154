import { message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import {BASE_URL} from "../pages/constant";
import api from "../pages/api";

const PopupFormBootstrap = () => {
  const [form, setForm] = useState({});

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    // message.success("Form Submitting..")
    // axios({
    //   method: "POST",
    //   data: ,
    //   withCredentials: true,
    //   url: ,
    // })

        api(BASE_URL).post(`book_of_trips/inquiry/add-inquiry`, {
          name: form.name,
          phone: form.phone,
          email: form.email,
          message: form.message,
        })
      .then((res) => {
        message.success("your inquiry has been taken");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <h3 className="Mon_font fw-bold fs-4 text-center">
              Want us to help you better?
              <br />
              <span className="text-warning">Connect with us </span>Right Away!
            </h3>
            <form onSubmit={submitHandler}>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Name
                </label>
                <input
                  required
                  onChange={handleChange}
                  name="name"
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Whatsapp Number
                </label>
                <input
                  required
                  onChange={handleChange}
                  name="phone"
                  type="number"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Destination
                </label>
                <input
                  required
                  onChange={handleChange}
                  name="email"
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Message
                </label>
                <input
                  required
                  onChange={handleChange}
                  name="message"
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="btn btn-warning d-block m-auto"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupFormBootstrap;
