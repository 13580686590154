import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import '../../../css/Style/LandingPage/TrendingLocation.css'
import { Link } from 'react-router-dom'
import axios from 'axios'
import {BASE_URL} from "../../pages/constant";
import api from "../../pages/api";

const TrendingLocation = () => {
    const [trending, setTrending] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const arrowRef = useRef();
    
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4, 
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    dots: true,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    dots: true,
                    infinite: true
                }
            }
        ]
    };;

    useEffect(() => {
        // axios({
        //     method: "get",
        //     withCredentials: true,
        //     url: `api/cityImages/get-all-city-image`,
        // }
        const host = BASE_URL;
        api(host)
            .get(`book_of_trips/cityImages/get-all-city-image`)
            .then((res) => {
            setTrending((res.data.data).reverse())
        }).catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
        <div className='container-fluid Mon_font my-5'>
            <div className="video__cont">
                {
                    trending?.length > 0 &&
                    <>
                        <div>
                            <h2 className="fw-bold">Trending Destinations</h2>
                            <div className='hr-line mb-3'></div>
                        </div>
                    </>
                }
                <Slider className='text-center' ref={arrowRef} {...settings}>
                    {
                        trending?.map((item, index) => {
                            if(index === 0){
                                return (
                                    <>
                                    <div key={item.id} >
                                        <Link to={`/category/${item.city.toLowerCase().replace(/\s+/g, "-")}`} className='trending__banner' style={{ background: `url(${item.image})` }}>
                                            {/* <h4>{item.city}</h4> */}
                                        </Link>
                                    </div>
                                    </>
                                )
                            }
                            if(index >= 1){
                                return (
                                    <>
                                    <div key={item.id} >
                                        <Link to={`/category/${item.city.toLowerCase().replace(/\s+/g, "-")}`} className='trending__banner' style={{ background: `url(${item.image})` }}>
                                            {/* <h4>{item.city}</h4> */}
                                        </Link>
                                    </div>
                                    </>
                                )
                            }
                        })
                    }
                </Slider>
                {
                    trending?.length > 3 &&
                    <div>
                        <button onClick={() => arrowRef.current.slickPrev()} className='arrow__left trending-arrow__left'><i className="fa-solid fa-chevron-left"></i></button>
                        <button onClick={() => arrowRef.current.slickNext()} className='arrow__right trending-arrow__right'><i className="fa-solid fa-chevron-right"></i></button>
                    </div>
                }
            </div>
        </div>
        </>
    )
}

export default TrendingLocation
