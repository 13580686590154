import axios from 'axios';
import React, { useState } from 'react';

const AddPdf = () => {
    const [file, setFile] = useState(null);

    const handleSubmit = async (e) => {
      e.preventDefault();
      const formData = new FormData();
      formData.append('pdfFile', file);
      try {
        await axios.post('/book_of_trips/upload/add-pdf', formData);
      } catch (error) {
        console.log(error);
      }
    };
  
    const handleFileChange = (e) => {
      setFile(e.target.files[0]);
    };

    const handleDownload = async (id) => {
        try {
          const response = await axios.get(`/book_of_trips/upload/download-pdf`, { responseType: 'blob' });
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'file.pdf');
          document.body.appendChild(link);
          link.click();
        } catch (error) {
          console.log(error);
        }
    };
  
    return (
        <div className='container mt-5 text-center'>
            <form onSubmit={handleSubmit}>
                <input required type="file" onChange={handleFileChange} />
                <button type="submit">Upload</button>
            </form>
            <button onClick={handleDownload}>Download</button>
        </div>
    );
  }

export default AddPdf
