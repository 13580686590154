import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../../../css/Style/HeroSection.css'
import Typewriter from 'typewriter-effect';

const HeroSection = ({ title, subTitle, trip, activeTrip, setExpertForm, custom_trip }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const isHome = location.pathname === "/";
    const isCategoryPage = location.pathname === "/category/:category";
    const isSingleTrip = location.pathname === `/${(activeTrip?.trip_url)?.toLowerCase().replace(/\s+/g, '-')}`
    // console.log("{(activeTrip?.heading)?.replace(/\s+/g, '-')}: ", `${(activeTrip?.heading)?.toLowerCase().replace(/\s+/g, '-')}`)
    const [search, setSeaarch] = useState(null);
    const handleSearch = (e) => {
        setSeaarch(e.target.value)
    }
    const searchTrip = trip?.filter(obj => obj.heading.toLowerCase().includes(search?.toLowerCase()))
    return (
        <div className={`hero__section ${!isHome ? 'other__section' : ''}`}>
            <div className="main__heading text-white text-center">
                {
                    custom_trip === false && isSingleTrip && (activeTrip?.customised === "true" ?
                        <div className='book__now__btn__get__itinerary d-flex justify-content-center p-3'>
                            <button onClick={() => setExpertForm(true)}>Get Quote</button>
                        </div>
                        :
                        <div className='book__now__btn__get__itinerary Mon_font'>
                            {/*<Link to={activeTrip?.pdfFile} target="_blank">Get Itinerary</Link>*/}
                            <button onClick={() => navigate(`/book/${activeTrip?.id}`)}>Book Now
                            </button>
                        </div>)
                }
            </div>
            {/* search input only for homepage */}
            {
                isHome &&
                <div className='search'>
                    {
                        isHome ?
                            <div className='typewriter-text'>
                                <h1 className='m-0 fw-bold'>
                                    {title}
                                </h1>
                                <h1 className='ms-2 fw-bold'>
                                    <Typewriter
                                        options={{
                                            autoStart: true, loop: true, delay: 50,
                                            strings: ['Ordinary', 'Chaos', 'Cliché',]
                                        }}
                                    />
                                </h1>
                            </div>
                            :
                            <h1 className='m-0'>
                                {title}
                            </h1>
                    }
                    <div className="search__location d-flex jutify-content-center align-items-center">
                        <input onChange={handleSearch} type="search" className="search__field txt-lg2" placeholder='Discover your next destination' />
                        {
                            !search &&
                            <i className='fa-solid fa-magnifying-glass'></i>
                        }
                    </div>
                    <div className={`searching__box ${search ? "" : "d-none"}`}>
                        {
                            search &&
                            searchTrip?.map(item => {
                                // console.log("${(item?.heading).replace(/\s+/g, '-')}: ", `${(item?.heading).replace(/\s+/g, '-')}`)
                                return <div className='searching-box-item' key={item.id} onClick={() => navigate(`/${(item?.trip_url.toLowerCase()).replace(/\s+/g, '-')}`)}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <p className='py-2 ps-2'>{item.heading.toUpperCase()}</p>
                                        <p className='py-2 pe-2' style={{fontSize: '12px'}}>{item.duration}</p>
                                    </div>
                                    <hr className='m-0'/>
                                </div>
                            })
                        }
                        {
                            searchTrip && searchTrip?.length === 0 &&
                            <p>No result found</p>
                        }
                    </div>
                </div>
            }

            {
                isHome &&
                <div className={`site__review`}>
                    <div className="container">
                        <div className="row">
                            <div className="col-6 col-sm-6 col-md-6 col-lg-3">
                                <div className='img__review text-white'>
                                    <i className=" fa-brands fa-instagram"></i>
                                    <span>Community of</span>
                                    <p>619k+ On Insta</p>
                                </div>
                            </div>
                            <div className="col-6 col-sm-6 col-md-6 col-lg-3">
                                <div className='img__review text-white'>
                                    <i className="fa-solid fa-indian-rupee-sign"></i>
                                    <span>Best Price</span>
                                    <p>Assured</p>
                                </div>
                            </div>
                            <div className="col-6 col-sm-6 col-md-6 col-lg-3">
                                <div className='img__review text-white'>
                                    <i className="fa-solid fa-book"></i>
                                    <span>1000+</span>
                                    <p>Successful Trips</p>
                                </div>
                            </div>
                            <div className="col-6 col-sm-6 col-md-6 col-lg-3">
                                <div className='img__review text-white'>
                                    <i className="fa-brands fa-google"></i>
                                    <span>1100+</span>
                                    <p>Google Reviews</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default HeroSection
