import React, {useRef} from "react";
import "../../../css/Style/LandingPage/Testimonial.css";
import Slider from "react-slick";

const Testmonials = (data) => {
    const arrowRef = useRef();
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2.4,
                    slidesToScroll: 3,
                    dots: true,
                    infinite: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
    };
  return (
    <div className="Mon_font testimonials__cont my-5 py-5">
      <div className="text-center ms-2">
        <h2>
          What Our Travellers <br className="d-block d-lg-none d-md-block" />{" "}
          Feel About Us
        </h2>
        <div className="hr-line mb-3"></div>
        <p className="text-center">Testimonial & Reviews</p>
      </div>
      {/*<div className="testimonials">*/}
      {/*  <div className="text-center">*/}
      {/*    <iframe*/}
      {/*      src="src/static/js/component/LandingPage"*/}
      {/*      title="YouTube video player"*/}
      {/*      frameBorder="0"*/}
      {/*      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"*/}
      {/*      allowFullScreen*/}
      {/*    ></iframe>*/}
      {/*  </div>*/}
      {/*</div>*/}
        <div className="container-fluid">
            <div className="video__cont">
                {data.testimonials?.length > 0 && (
                    <>
                        <h2 className="Mon_font">
                            <small>Feedbacks & Testimonials</small>
                        </h2>
                        <div className="hr-line-head">
                            <div className="hr-line m-0 mb-3 "></div>
                        </div>
                    </>
                )}
                <Slider className="text-center" ref={arrowRef} {...settings}>
                    {data.testimonials?.map((item) => {
                        const date = new Date(item.date)
                            .toLocaleString("en-US", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                            })
                            .replace(",", "");
                        return (
                            <div className="review__card Mon_font ">
                                {/* <div className="text-start"><i className="fa-solid fa-quote-left"></i></div> */}
                                <div>
                                    <img
                                        style={{ borderRadius: "50%" }}
                                        className=""
                                        src={item?.userimage}
                                        alt="img"
                                    />
                                </div>
                                <div className="d-flex">
                                    <div className="text-start">
                                        <h5>{item.heading}</h5>
                                        <p className="date m-0 p-0">
                                            {date.split(" ")[1] +
                                                " " +
                                                date.split(" ")[0] +
                                                " " +
                                                date.split(" ")[2]}
                                        </p>
                                        <p className="desc text-start">{item.description}</p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </Slider>
                {data.testimonials?.length > 4 && (
                    <div>
                        <button
                            onClick={() => arrowRef.current.slickPrev()}
                            className="arrow__left"
                        >
                            <i className="fa-solid fa-chevron-left"></i>
                        </button>
                        <button
                            onClick={() => arrowRef.current.slickNext()}
                            className="arrow__right"
                        >
                            <i className="fa-solid fa-chevron-right"></i>
                        </button>
                    </div>
                )}
            </div>
        </div>
    </div>
  );
};

export default Testmonials;
