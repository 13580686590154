import React, {useEffect, useState} from 'react'
import Layout from '../../component/Admin/AdminLayout'
import AdminHeader from './component/AdminHeader'
import {Link, useNavigate, useParams} from 'react-router-dom'
import axios from 'axios'
import {message} from 'antd'
import {useDispatch} from 'react-redux'
import Multiselect from 'multiselect-react-dropdown'
import api from "../api";
import {BASE_URL} from "../constant";

const EditTrip = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams()
    const [loading, setLoading] = useState(false)
    const [costing, setCosting] = useState([{name: null, price: null, place: null}])
    const [itinerary, setItinerary] = useState([{heading: null, description: null}])
    const [thingsToCarry, setThingsToCarry] = useState([{name: null, description: null}])
    const [mustCarry, setMustCarry] = useState([{name: null}])
    const [tnc, setTnc] = useState([{name: null}])
    const [testimonials, setTestimonials] = useState([{heading: null, date: null, description: null, userimage: null}])
    const [notes, setNotes] = useState([{name: null}])
    const [inclusion, setInclusion] = useState([{name: null}])
    const [exclusion, setExclusion] = useState([{name: null}])
    const [videos, setVideos] = useState([{url: null}])
    const [category, setCategory] = useState(null)
    const [selectedCategory, setSelectdCategory] = useState(null)
    const [image, setImage] = useState([]); // gallery data before upload cloudinary
    const [links, setLinks] = useState([]); // gallery image link
    const [selectedDates, setSelectedDates] = useState([{date: null}]);
    const [dateStatus, setDateStatus] = useState([{status: null, date: null}]);
    const [inputFields, setInputFields] = useState({customised: 'false'});
    const [allCategory, setAllCategory] = useState([]);
    const [accomodationLinks, setAccomodationLinks] = useState([]);
    const [accomodationImage, setAccomodationImage] = useState([]);

    // get all category
    const getAllCategory = async () => {
        await api(BASE_URL).get(`book_of_trips/category/get-all-category`).then((res) => {
            let categories = []
            if (res.data.data) {
                res.data?.data.forEach(category => {
                    categories.push(category.name);
                });
            }
            setAllCategory(categories)

        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        getAllCategory()
        // eslint-disable-next-line
    }, [])
    const getTrip = () => {
        // axios({
        //     method: "POST",
        //     data: {
        //         id: params.id
        //     },
        //     withCredentials: true,
        //     url: `book_of_trips/trips/get-trip`,
        // })
        api(BASE_URL).post(`book_of_trips/trips/get-trip`, {
            id: params.id
        })
            .then((res) => {
                setCategory(res.data.data?.category)
                setSelectedDates(res.data.data?.dates)
                setDateStatus(res.data.data?.dateStatus)
                setCosting(res.data.data?.costing)
                setItinerary(res.data.data?.itinerary)
                setTestimonials(res.data.data?.testimonials)
                setThingsToCarry(res.data.data?.thingstocarry)
                setMustCarry(res.data.data?.mustcarry)
                setTnc(res.data.data?.tnc)
                setNotes(res.data.data?.notes)
                setInclusion(res.data.data?.inclusion)
                setExclusion(res.data.data?.exclusion)
                setLinks(res.data.data?.gallery)
                setAccomodationLinks(res.data.data?.accomodation)
                setVideos(res.data.data?.videos)
                setSelectdCategory(res.data.data?.category)
                setInputFields({
                    ...inputFields,
                    heading: res.data.data?.heading,
                    trip_url: res.data.data?.trip_url,
                    duration: res.data.data?.duration,
                    departure: res.data.data?.departure,
                    season: res.data.data?.season,
                    location: res.data.data?.location,
                    totalSeat: res.data.data?.totalSeat,
                    tripPriority: res.data.data?.priority,
                    highlighted: res.data.data?.highlighted,
                    customised: res.data.data?.customised,
                    trending: res.data.data?.trending,
                    thoughts: res.data.data?.thoughts,
                    about: res.data.data?.about,
                    image: res.data.data?.image,
                    coverImage: res.data.data?.cover_image,
                    pdfFile: res.data.data?.pdfFile
                })
                // setInputFields(res.data.data?.heading)
            }).catch((err) => {
            console.log(err);
        });
    }
    useEffect(() => {
        getTrip()
        // eslint-disable-next-line
    }, [])

    //========================= EDIT TRIP ===================================================================

    //on submit form
    const submit = (e) => {
        e.preventDefault();
        const formData = {
            id: params.id,
            pdfFile: inputFields.pdfFile,
            heading: inputFields.heading,
            trip_url: inputFields.trip_url,
            duration: inputFields.duration,
            departure: inputFields.departure,
            thoughts: inputFields.thoughts,
            about: inputFields.about,
            image: inputFields.image,
            coverImage: inputFields.coverImage,
            totalSeat: inputFields.totalSeat,
            priority: inputFields.tripPriority,
            location: inputFields.location,
            customised: inputFields.customised,
            season: inputFields.season,
            highlighted: inputFields.highlighted,
            trending: inputFields.trending,
            costing: costing,
            itinerary: itinerary,
            testimonials: testimonials,
            thingstocarry: thingsToCarry,
            mustcarry: mustCarry,
            tnc: tnc,
            inclusion: inclusion,
            exclusion: exclusion,
            notes: notes,
            videos: videos,
            gallery: links,
            accomodation: accomodationLinks,
            dates: selectedDates,
            dateStatus: dateStatus,
            category: category,
        }
        setLoading(true)
        // axios({
        //     method: "POST",
        //     data: formData,
        //     withCredentials: true,
        //     url: `${BASE_URL}/book_of_trips/trips/create-trip`,
        // })
        api(BASE_URL).post(`/book_of_trips/trips/edit-trip`,
            formData,
        ).then((res) => {
            setTimeout(() => {
                setLoading(false)
            }, 1500);
            if (res.data.success) {
                navigate('/all-trips');
            }
            message.success(res.data.message)
        }).catch((err) => {
            setTimeout(() => {
                setLoading(false)
            }, 1500);
            console.log(err);
        });
    }

    //on gallery submit
    const onUpload = async () => {
        if (image.length !== 0) {
            const uploadCloudinary = async (file) => {
                const formData = new FormData()
                formData.append('file', file)

                const {data} = await api(BASE_URL).post(`/book_of_trips/admin/upload`, formData);
                return {publicId: data?.public_id, url: data?.secure_url}
            }
            try {
                let arr = []
                setLoading(true)
                for (let i = 0; i < image.length; i++) {
                    const data = await uploadCloudinary(image[i]);
                    arr.push(data)
                }
                setLoading(false)
                setLinks(arr)
            } catch (error) {
                console.log(error)
            }
        } else {
            message.error('Please upload image')
        }
    }

    //on accomodation gallary submit
    const onAccomodationUpload = async () => {
        if (accomodationImage.length !== 0) {
            const uploadCloudinary = async (file) => {
                const formData = new FormData()
                formData.append('file', file)
                // formData.append('upload_preset', '');
                // formData.append('cloud_name', '');
                const {data} = await api(BASE_URL).post(`/book_of_trips/admin/upload`, formData);
                return {publicId: data?.public_id, url: data?.secure_url}
            }
            try {
                let arr = []
                setLoading(true)
                for (let i = 0; i < accomodationImage.length; i++) {
                    const data = await uploadCloudinary(accomodationImage[i]);
                    arr.push(data)
                }
                setLoading(false)
                setAccomodationLinks(arr)
            } catch (error) {
                console.log(error)
            }
        } else {
            message.error('Please upload image')
        }

    }

    //image API: api.cloudinary.com
    const postDetails = (image) => {
        if (!image) {
            return message.error('Please Select an image');
        }
        if (image.type === 'image/jpeg' || image.type === 'image/png') {
            const data = new FormData();
            data.append('file', image);
            const headers = {};
            headers["authorization"] = `${window.localStorage.getItem("auth_key")}`
            fetch(`${BASE_URL}/book_of_trips/admin/upload`, {
                method: 'post',
                body: data,
                headers: headers,
            }).then((res) => res.json()).then((data) => {
                setInputFields({...inputFields, image: data.secure_url})
            }).catch((err) => {
                message.error(err);
            })
        } else {
            return message.error('Please Select an image');
        }
    };

    const postDetailsCoverImage = (image) => {
        if (!image) {
            return message.error('Please Select an image');
        }
        if (image.type === 'image/jpeg' || image.type === 'image/png') {
            const data = new FormData();
            data.append('file', image);
            const headers = {};
            headers["authorization"] = `${window.localStorage.getItem("auth_key")}`

            fetch(`${BASE_URL}/book_of_trips/admin/upload`, {
                method: 'post',
                body: data,
                headers: headers,
            }).then((res) => res.json()).then((data) => {
                setInputFields({...inputFields, coverImage: data.secure_url})
            }).catch((err) => {
                message.error(err);
            })
        } else {
            return message.error('Please Select an image');
        }
    };

    // onChange normal form
    const handleOnChange = async (event, elm) => {
        if (elm === 'tripImage') {
            postDetails(event.target.files[0])
        }

        if (elm === 'tripCoverImage') {
            postDetailsCoverImage(event.target.files[0])
        }
        if (elm === 'tripGallery') {
            setLinks([])
            setImage(event.target.files);
        }
        if (elm === 'tripAccomodation') {
            setAccomodationLinks([])
            setAccomodationImage(event.target.files);
        }
        if (elm === 'highlighted') {
            let value = false;
            if (typeof event.currentTarget.value === 'string') {
                (event.target.value === 'true' ? value = true : value = false);
            }
            setInputFields({...inputFields, highlighted: value})
        }
        if (elm === 'trending') {
            let value = false;
            if (typeof event.currentTarget.value === 'string') {
                (event.target.value === 'true' ? value = true : value = false);
            }
            setInputFields({...inputFields, trending: value})
        }
        if (elm === 'customised') {
            let value = false;
            if (typeof event.currentTarget.value === 'string') {
                (event.target.value === 'true' ? value = true : value = false);
            }
            setInputFields({...inputFields, customised: value})
        }
        setInputFields({...inputFields, [event.target.name]: event.target.value})
    }

    // onChange Dynamic form
    const handleFormChanges = (event, index, value, value2) => {
        if (value === 'costing') {
            let data = [...costing];
            const {name, value} = event.target;
            let targetValue = event.target.value;
            if (name === 'price') {
                targetValue = targetValue.trim();
            }
            data[index][event.target.name] = targetValue;
            setCosting(data);
        }
        if (value === 'itinerary') {
            let data = [...itinerary];
            data[index][event.target.name] = event.target.value;
            setItinerary(data);
        }
        if (value === 'thingsToCarry') {
            let data = [...thingsToCarry];
            data[index][event.target.name] = event.target.value;
            setThingsToCarry(data);
        }
        if (value === 'mustCarry') {
            let data = [...mustCarry];
            data[index][event.target.name] = event.target.value;
            setMustCarry(data);
        }
        if (value === 'testimonials') {
            if (value2 === 'userimage') {
                const image = event.target.files[0]
                if (image.type === 'image/jpeg' || image.type === 'image/png' || image.type === 'image/jpg') {
                    const data = new FormData();
                    data.append('file', image);
                    const headers = {};
                    headers["authorization"] = `${window.localStorage.getItem("auth_key")}`
                    fetch(`${BASE_URL}/book_of_trips/admin/upload`, {
                        method: 'post',
                        body: data,
                        headers: headers,
                    }).then((res) => res.json()).then((data) => {
                        let tesimonialsData = [...testimonials];
                        tesimonialsData[index][event.target.name] = data.secure_url;
                        setTestimonials(tesimonialsData);

                    }).catch((err) => {
                        message.error(err);
                    })
                } else {
                    return message.error('Please Select an image');
                }
            }
            let data = [...testimonials];
            data[index][event.target.name] = event.target.value;
            setTestimonials(data);
        }
        if (value === 'tnc') {
            let data = [...tnc];
            data[index][event.target.name] = event.target.value;
            setTnc(data);
        }
        if (value === 'notes') {
            let data = [...notes];
            data[index][event.target.name] = event.target.value;
            setNotes(data);
        }
        if (value === 'inclusion') {
            let data = [...inclusion];
            data[index][event.target.name] = event.target.value;
            setInclusion(data);
        }
        if (value === 'exclusion') {
            let data = [...exclusion];
            data[index][event.target.name] = event.target.value;
            setExclusion(data);
        }
        if (value === 'youtubeVideos') {
            let data = [...videos];
            data[index][event.target.name] = event.target.value;
            setVideos(data);
        }
        if (value === 'date') {
            let data = [...selectedDates];
            data[index][event.target.name] = event.target.value;
            setSelectedDates(data);
        }
        if (value === 'dateStatus') {
            let data = [...dateStatus];
            data[index][event.target.name] = event.target.value;
            setDateStatus(data);
        }
    }

    // add fields
    const addFields = (value) => {
        if (value === 'costing') {
            const object = {
                name: null,
                price: null
            }
            setCosting([...costing, object])
        }
        if (value === 'itinerary') {
            const object = {
                heading: null,
                description: null
            }
            setItinerary([...itinerary, object])
        }
        if (value === 'testimonials') {
            const object = {
                heading: null,
                date: null,
                description: null,
                userimage: null
            }
            setTestimonials([...testimonials, object])
        }
        if (value === 'thingsToCarry') {
            const object = {
                name: null,
                description: null
            }
            setThingsToCarry([...thingsToCarry, object])
        }
        if (value === 'mustCarry') {
            const object = {
                name: null
            }
            setMustCarry([...mustCarry, object])
        }
        if (value === 'tnc') {
            const object = {
                name: null,
            }
            setTnc([...tnc, object])
        }
        if (value === 'notes') {
            const object = {
                name: null,
            }
            setNotes([...notes, object])
        }
        if (value === 'inclusion') {
            const object = {
                name: null,
            }
            setInclusion([...inclusion, object])
        }
        if (value === 'exclusion') {
            const object = {
                name: null,
            }
            setExclusion([...exclusion, object])
        }
        if (value === 'youtubeVideos') {
            const object = {
                url: null,
            }
            setVideos([...videos, object])
        }
        if (value === 'date') {
            const object = {
                date: null,
            }
            setSelectedDates([...selectedDates, object])
        }
        if (value === 'dateStatus') {
            const object = {
                status: null,
                date: null
            }
            setDateStatus([...dateStatus, object])
        }
    }

    // remove fields
    const removeFields = (index, value) => {
        if (value === 'costing') {
            const data = [...costing];
            data.splice(index, 1)
            setCosting(data)
        }
        if (value === 'itinerary') {
            const data = [...itinerary];
            data.splice(index, 1)
            setItinerary(data)
        }
        if (value === 'testimonials') {
            const data = [...testimonials];
            data.splice(index, 1)
            setTestimonials(data)
        }
        if (value === 'thingsToCarry') {
            const data = [...thingsToCarry];
            data.splice(index, 1)
            setThingsToCarry(data)
        }
        if (value === 'mustCarry') {
            const data = [...mustCarry];
            data.splice(index, 1)
            setMustCarry(data)
        }
        if (value === 'tnc') {
            const data = [...tnc];
            data.splice(index, 1)
            setTnc(data)
        }
        if (value === 'notes') {
            const data = [...notes];
            data.splice(index, 1)
            setNotes(data)
        }
        if (value === 'inclusion') {
            const data = [...inclusion];
            data.splice(index, 1)
            setInclusion(data)
        }
        if (value === 'exclusion') {
            const data = [...exclusion];
            data.splice(index, 1)
            setExclusion(data)
        }
        if (value === 'youtubeVideos') {
            const data = [...videos];
            data.splice(index, 1)
            setVideos(data)
        }
        if (value === 'date') {
            const data = [...selectedDates];
            data.splice(index, 1)
            setSelectedDates(data)
        }
        if (value === 'dateStatus') {
            const data = [...dateStatus];
            data.splice(index, 1)
            setDateStatus(data)
        }
    }

    const handleInvalidInput = (event) => {
        const {name, value} = event.target;
        console.log("handleInvalidInput event.target", event.target)
        if (name === 'price' && !/^\d*$/.test(value)) {
            document.getElementById('priceError').textContent = 'Please enter a valid integer';
        } else {
            document.getElementById('priceError').textContent = '';
        }
        event.target.setCustomValidity('Please enter a valid integer');
    };

    const handleInput = (event) => {
        document.getElementById('priceError').textContent = '';
        event.target.setCustomValidity('');
    };

    const getNextFourWeekendDates = (startDate) => {
        const weekends = [];
        let date = new Date(startDate);

        let lastDay = date.getDay()

        // Add the next four WoW days
        for (let i = 0; i < 4; i++) {
            let finalDate = date.setDate(date.getDate() + 7);
            weekends.push(new Date(finalDate));
        }

        return weekends;
    };

    const getNextFourFridaysDates = (startDate) => {
        const weekends = [];
        let date = new Date(startDate);

        let lastDay = 5

        if (date.getDay() !== lastDay && date.getDay() !== 0) {
            while (date.getDay() !== lastDay) {
                date.setDate(date.getDate() + 1);
            }
        } else if (date.getDay() === 0) {
            // If it's Sunday, start from next Saturday
            date.setDate(date.getDate() + lastDay);
        }

        // Add the next four WoW days
        for (let i = 0; i < 4; i++) {
            let finalDate = date.setDate(date.getDate() + 7);
            weekends.push(new Date(finalDate));
        }

        return weekends;
    };

    const getNextFourFridaysDateStatus = (startDate) => {
        const weekends = [];
        let date = new Date(startDate);

        let lastDay = 5

        if (date.getDay() !== lastDay && date.getDay() !== 0) {
            while (date.getDay() !== lastDay) {
                date.setDate(date.getDate() + 1);
            }
        } else if (date.getDay() === 0) {
            // If it's Sunday, start from next Saturday
            date.setDate(date.getDate() + lastDay);
        }

        // Add the next four WoW days
        for (let i = 0; i < 4; i++) {
            let finalDate = date.setDate(date.getDate() + 7);
            weekends.push({date: new Date(finalDate), status: "Seat Available"});
        }

        return weekends;
    };

    const getNextFourWeekendDateStatus = (startDate) => {
        const weekends = [];
        let date = new Date(startDate);
        for (let i = 0; i < 4; i++) {
            let finalDate = date.setDate(date.getDate() + 7);
            weekends.push({date: new Date(finalDate), status: "Seat Available"});
        }

        return weekends;
    };



    const addNextFourWeeks = (field) => {
        if (field === 'date') {
            let firstDate = selectedDates[selectedDates.length - 1].date;
            if (!selectedDates[selectedDates.length - 1].date) {
                firstDate = new Date();
            }

            let lastDate = selectedDates.length > 0 ? firstDate : new Date();
            let newDates = getNextFourWeekendDates(lastDate).map(date => ({date: date.toISOString().split('T')[0]}));
            setSelectedDates([...selectedDates, ...newDates]);
            addNextFourWeeksDateStatus("dateStatus", lastDate)
        }
    };

    const addFourFridays = (field) => {
        if (field === 'date') {
            let firstDate = selectedDates[selectedDates.length - 1].date;
            if (!selectedDates[selectedDates.length - 1].date) {
                firstDate = new Date();
            }

            let lastDate = selectedDates.length > 0 ? firstDate : new Date();
            let newDates = getNextFourFridaysDates(lastDate).map(date => ({date: date.toISOString().split('T')[0]}));
            setSelectedDates([...selectedDates, ...newDates]);
            addFourFridaysDateStatus("dateStatus", lastDate)
        }
    };

    const addFourFridaysDateStatus = (field, dateGiven) => {
        if (field === 'dateStatus') {
            let lastDateStatus = dateGiven ? dateGiven : dateStatus.length > 0 && dateStatus[dateStatus.length - 1].status ? dateStatus[dateStatus.length - 1].date : new Date();
            let newDatesStatus = getNextFourFridaysDateStatus(lastDateStatus).map(date => ({date: date.date.toISOString().split('T')[0], status: date.status}));
            setDateStatus([...dateStatus, ...newDatesStatus]);
        }
    };

    const addNextFourWeeksDateStatus = (field, dateGiven) => {
        if (field === 'dateStatus') {
            let lastDateStatus = dateGiven ? dateGiven : dateStatus.length > 0 && dateStatus[dateStatus.length - 1].status ? dateStatus[dateStatus.length - 1].date : new Date();
            let newDatesStatus = getNextFourWeekendDateStatus(lastDateStatus).map(date => ({date: date.date.toISOString().split('T')[0],  status: date.status}));
            setDateStatus([...dateStatus, ...newDatesStatus]);
        }
    };



    //========================= END EDIT TRIP ===================================================================
    return (
        <Layout>
            <AdminHeader title='Edit Trips' redirect='/all-trips' btnName='Add Trip' btnUrl='/add-trip'/>
            <div className="container Mon_font px-4 pb-4">
                <form className="row g-3" onSubmit={submit}>
                    <div className="col-12">
                        <button type='submit'
                                className='btn btn-success w-100'>{loading ? 'Loading...' : 'Submit'}</button>
                    </div>
                    <div className="col-6">
                        <label htmlFor="heading" className="form-label">Title</label>
                        <input placeholder='title' value={inputFields?.heading} className="form-control" id="heading"
                               name="heading" type="text" onChange={(event) => {
                            handleOnChange(event)
                        }}/>
                    </div>
                    <div className="col-6">
                        <label htmlFor="heading" className="form-label">Trip URL</label>
                        <input placeholder='trip_url' value={inputFields?.trip_url} className="form-control" id="trip_url"
                               name="trip_url" type="text" onChange={(event) => {
                            handleOnChange(event)
                        }}/>
                    </div>
                    <div className="col-3">
                        <label htmlFor="duration" className="form-label">Duration</label>
                        <select className="form-select" value={inputFields?.duration} name="duration" id="duration"
                                onChange={(event) => {
                                    handleOnChange(event)
                                }}>
                            <option selected>Select</option>
                            <option value="1N-2D">1N-2D</option>
                            <option value="2N-3D">2N-3D</option>
                            <option value="3N-4D">3N-4D</option>
                            <option value="4N-5D">4N-5D</option>
                            <option value="5N-6D">5N-6D</option>
                            <option value="6N-7D">6N-7D</option>
                            <option value="7N-8D">7N-8D</option>
                            <option value="8N-9D">8N-9D</option>
                            <option value="9N-10D">9N-10D</option>
                            <option value="10N-11D">10N-11D</option>
                        </select>
                    </div>
                    <div className="col-3">
                        <label htmlFor="departure" className="form-label">Departure</label>
                        <input placeholder='mumbai' value={inputFields?.departure} className="form-control"
                               id="departure" name="departure" type="text" onChange={(event) => {
                            handleOnChange(event)
                        }}/>
                    </div>
                    {/* Select Multiple Category */}
                    <div className="col-6">
                        <label htmlFor="category" className="form-label">Catgory</label>
                        <Multiselect
                            id="category"
                            className="multiselect-wrapper"
                            isObject={false}
                            onRemove={(event) => {
                                if (event.length === 0) {
                                    setCategory(null)
                                } else {
                                    setCategory(event)
                                }
                            }}
                            onSelect={(event) => {
                                setCategory(event)
                            }}
                            options={allCategory}
                            selectedValues={selectedCategory && selectedCategory}
                        />
                    </div>
                    {/* Select Multiple Category */}

                    <div className="col-3">
                        <label htmlFor="location" className="form-label">Location</label>
                        <input placeholder='manali' value={inputFields?.location} className="form-control" id="location"
                               name="location" type="text" onChange={(event) => {
                            handleOnChange(event)
                        }}/>
                    </div>
                    <div className="col-3">
                        <label htmlFor="totalSeat" className="form-label">Total Seat</label>
                        <input placeholder='30' value={inputFields?.totalSeat} className="form-control" id="totalSeat"
                               name="totalSeat" type="text" onChange={(event) => {
                            handleOnChange(event)
                        }}/>
                    </div>
                    <div className="col-3">
                        <label htmlFor="tripPriority" className="form-label">Trip Priority</label>
                        <input
                            placeholder='1'
                            value={inputFields?.tripPriority}
                            className="form-control"
                            id="tripPriority"
                            name="tripPriority"
                            type="number"
                            onChange={(event) => {
                                handleOnChange(event)
                            }}
                            maxLength={5}
                            step="1"
                        />
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className="col-4">
                            <label htmlFor="season" className="form-label">Season</label>
                            <select className="form-select" name="season" value={inputFields?.season} id="season"
                                    onChange={(event) => {
                                        handleOnChange(event)
                                    }}>
                                <option selected>Choose</option>
                                <option value="spring">Spring</option>
                                <option value="summer">Summer</option>
                                <option value="monsoon">Monsoon</option>
                                <option value="pre-winter">Pre-winter</option>
                                <option value="winter">Winter</option>
                            </select>
                        </div>
                        <div className='d-flex justify-content-between col-3 align-items-center mb-2 mt-4'>
                            <h5 className='m-0'>Highlighted: </h5>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="highlighted" value='true'
                                       id="highlightedYes" onChange={(event) => {
                                    handleOnChange(event, 'highlighted')
                                }}/>
                                <label className="form-check-label" htmlFor="highlightedYes">
                                    Yes
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="highlighted" value='false'
                                       id="highlightedNo" onChange={(event) => {
                                    handleOnChange(event, 'highlighted')
                                }}/>
                                <label className="form-check-label" htmlFor="highlightedNo">
                                    No
                                </label>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between col-3 align-items-center mb-2 mt-4'>
                            <h5 className='m-0'>Trending: </h5>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="trending" value='true'
                                       id="trendingYes" onChange={(event) => {
                                    handleOnChange(event, 'trending')
                                }}/>
                                <label className="form-check-label" htmlFor="trendingYes">
                                    Yes
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="trending" value='false'
                                       id="trendingNo" onChange={(event) => {
                                    handleOnChange(event, 'trending')
                                }}/>
                                <label className="form-check-label" htmlFor="trendingNo">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <label htmlFor="thoughts" className="form-label">Thoughts</label>
                        <input value={inputFields?.thoughts} type="text" name='thoughts' className="form-control"
                               id="thoughts" placeholder="Experience the incredible wild life of Bali"
                               onChange={(event) => {
                                   handleOnChange(event)
                               }}/>
                    </div>
                    <div className="col-12">
                        <label htmlFor="about" className="form-label">About</label>
                        <textarea value={inputFields?.about} type="text" name='about' className="form-control"
                                  id="about" placeholder="Experience the incredible wild life of Bali"
                                  onChange={(event) => {
                                      handleOnChange(event)
                                  }}/>
                    </div>

                    <div className='d-flex justify-content-between flex-wrap'>
                        <div className="col-3">
                            <label htmlFor="tripImage" className="form-label">Select image</label>
                            <input type="file" accept="image/*" className="form-control" id="tripImage"
                                   onChange={(event) => {
                                       handleOnChange(event, 'tripImage')
                                   }}/>
                            {inputFields?.image && <div className="col-4">
                                <img className='p-2 rounded border mt-3' width={150} src={inputFields?.image}
                                     alt="Loading"/>
                            </div>}
                        </div>

                        <div className="col-3">
                            <label htmlFor="tripCoverImage" className="form-label">Select cover image</label>
                            <input type="file" accept="image/*" className="form-control" id="tripCoverImage"
                                   onChange={(event) => {
                                       handleOnChange(event, 'tripCoverImage')
                                   }}/>
                            {inputFields?.coverImage && <div className="col-4">
                                <img className='p-2 rounded border mt-3' width={150} src={inputFields?.coverImage}
                                     alt="Loading"/>
                            </div>}
                        </div>
                        {/*<div className="col-5">*/}
                        {/*    <label htmlFor="trip-pdf" className="form-label">Pdf File</label>*/}
                        {/*    <input value={inputFields?.pdfFile} className="form-control" id="trip-pdf" name="pdfFile" type="text" onChange={(event) => handleOnChange(event)}/>*/}
                        {/*</div>*/}
                        <div className='d-flex justify-content-between col-3 mb-2 mt-4'>
                            <h5 className='m-0'>Customised: </h5>
                            <div className="form-check">
                                <label className="form-check-label" htmlFor="customisedYes">
                                    Yes
                                </label>
                                <input className="form-check-input" type="radio" name="customised" value='true'
                                       id="customisedYes" onChange={(event) => {
                                    handleOnChange(event, 'customised')
                                }}/>
                            </div>
                            <div className="form-check">
                                <label className="form-check-label" htmlFor="customisedNo">
                                    No
                                </label>
                                <input className="form-check-input" type="radio" name="customised" value='false'
                                       id="customisedNo" onChange={(event) => {
                                    handleOnChange(event, 'customised')
                                }}/>
                            </div>
                        </div>
                    </div>
                    <hr className='mt-5'/>

                    {/* multiple select dates*/}
                    {<div>
                        <h3 className='mb-1 mt-2'>Select Date</h3>
                        <div>
                            {selectedDates?.map((form, index) => {
                                return (
                                    <div key={index} className='d-flex flex-row gap-3 my-2 align-items-end'>
                                        <div className="col-8">
                                            <input value={form.date} name='date' placeholder='name' type="date"
                                                   className="form-control" id="selectDate"
                                                   onChange={(event) => handleFormChanges(event, index, 'date')}
                                            />
                                        </div>
                                        <div className="col-2">
                                            <Link to='#' className="btn btn-danger btn-sm"
                                                  onClick={() => removeFields(index, 'date')}>Remove</Link>
                                        </div>
                                    </div>
                                )
                            })}
                            <Link to='#' className="btn btn-secondary btn-sm mx-2" onClick={() => {
                                addFields('date')
                            }}>Add More</Link>
                            <Link to='#' className="btn btn-secondary btn-sm mx-2" onClick={() => {
                                addFourFridays('date')
                            }}>Add Next Four Fridays</Link>
                            <Link to='#' className="btn btn-secondary btn-sm mx-2" onClick={() => {
                                addNextFourWeeks('date')
                            }}>Add Next Four Weeks</Link>


                        </div>
                        <hr className='mt-5'/>
                    </div>}
                    {/* end of multiple select dates*/}

                    {/*add mutiple text in dates*/}
                    {<div className='m-0 p-0'>
                        <h5 className='mb-1 mt-4 ps-4 py-2 text-white rounded bg-secondary'>Set Date Status</h5>
                        <div className='rounded border border-2 p-3 mt-4' style={{backgroundColor: '#f5f5f5'}}>
                            {dateStatus?.map((form, index) => {
                                return (
                                    <div key={index} className='d-flex flex-row gap-3 my-2 align-items-end'>
                                        <div className="col-4">
                                            <input value={form?.date} name='date' placeholder='name' type="date"
                                                   className="form-control"
                                                   onChange={(event) => handleFormChanges(event, index, 'dateStatus')}
                                                   maxLength={15}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <input value={form?.status} name='status' placeholder='name' type="text"
                                                   className="form-control"
                                                   onChange={(event) => handleFormChanges(event, index, 'dateStatus')}
                                                   maxLength={15}
                                            />
                                        </div>
                                        <div className="col-2">
                                            <Link to='#' className="btn btn-danger btn-sm"
                                                  onClick={() => removeFields(index, 'dateStatus')}>Remove</Link>
                                        </div>
                                    </div>
                                )
                            })}
                            <Link to='#' className="btn btn-secondary btn-sm mx-2" onClick={() => {
                                addFields('dateStatus')
                            }}>Add More</Link>

                            <Link to='#' className="btn btn-secondary btn-sm mx-2" onClick={() => {
                                addFourFridaysDateStatus('dateStatus')
                            }}>Add Next Four Fridays</Link>
                            <Link to='#' className="btn btn-secondary btn-sm mx-2" onClick={() => {
                                addNextFourWeeksDateStatus('dateStatus')
                            }}>Add Next Four Weeks</Link>

                        </div>
                    </div>}
                    {/*add mutiple text in dates*/}


                    {/* costing dynamic form field */}
                    <h3 className='mb-1 mt-2'>Costing</h3>
                    <div>
                        {costing?.map((form, index) => {
                            return (
                                <div key={index} className='d-flex flex-row gap-3 my-2 align-items-end'>
                                    <div className="col-3 w-10">
                                        <label htmlFor="tripCosting" className="form-label">Add Place</label>

                                        <input name='place' placeholder='place' type="text"
                                               className="form-control" value={form.place} id="tripCosting"
                                               onChange={(event) => handleFormChanges(event, index, 'costing')}
                                        />
                                    </div>

                                    <div className="col-3 w-10">
                                        <label htmlFor="tripCosting" className="form-label">Add Costing</label>
                                        <input name='name' placeholder='name' type="text"
                                               className="form-control" value={form.name} id="tripCosting"
                                               onChange={(event) => handleFormChanges(event, index, 'costing')}
                                        />
                                    </div>
                                    <div className="col-2">
                                        <label htmlFor="inputCity" className="form-label">Price</label>
                                        <input name='price'
                                               placeholder='price'
                                               type="text"
                                               className="form-control"
                                               id="price"
                                               pattern="\d+"
                                               onChange={(event) => handleFormChanges(event, index, 'costing')}
                                               value={form.price}
                                               onInvalid={(event) => handleInvalidInput(event)}
                                               onInput={(event) => handleInput(event)}


                                        />
                                        <span id="priceError" className="error-message"></span>
                                    </div>
                                    <div className="col-2">
                                        <Link to='#' className="btn btn-danger btn-sm"
                                              onClick={() => removeFields(index, 'costing')}>Remove</Link>
                                    </div>
                                </div>
                            )
                        })}
                        <Link to='#' className="btn btn-secondary btn-sm" onClick={() => {
                            addFields('costing')
                        }}>Add More</Link>
                    </div>
                    <hr className='mt-5'/>
                    {/* end of costing dynamic form field */}

                    {/* itinerary dynamic form field */}
                    <h3 className='mb-1 mt-2'>Itinerary</h3>
                    <div>
                        {itinerary?.map((form, index) => {
                            return (
                                <div key={index} className='d-flex flex-column gap-3 my-2 align-items-start'>
                                    <div className="col-md-12">
                                        <label htmlFor="itineraryHeading" className="form-label">Add Itinerary</label>
                                        <input name='heading' placeholder='name' type="text"
                                               className="form-control" id="itineraryHeading"
                                               onChange={(event) => handleFormChanges(event, index, 'itinerary')}
                                               value={form.heading}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="itineraryDescription" className="form-label">Description</label>
                                        <textarea name='description' placeholder='description' type="text"
                                                  className="form-control" id="itineraryDescription"
                                                  onChange={(event) => handleFormChanges(event, index, 'itinerary')}
                                                  value={form.description}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <Link to='#' className="btn btn-danger btn-sm"
                                              onClick={() => removeFields(index, 'itinerary')}>Remove</Link>
                                    </div>
                                </div>
                            )
                        })}
                        <Link to='#' className="btn btn-secondary btn-sm mt-2" onClick={() => addFields('itinerary')}>Add
                            More</Link>
                    </div>
                    <hr className='mt-5'/>
                    {/* end of itinerary dynamic form field */}

                    {/* things to carry dynamic form field */}
                    <h3 className='mb-1 mt-2'>Things to carry</h3>
                    <div>
                        {thingsToCarry.map((form, index) => {
                            return (
                                <div key={index} className='d-flex flex-column gap-3 my-2 align-items-start'>
                                    <div className="col-md-12">
                                        <input name='name' placeholder='name' type="text"
                                               className="form-control"
                                               onChange={(event) => handleFormChanges(event, index, 'thingsToCarry')}
                                               value={form.name}
                                        />
                                    </div>
                                    {/*<div className="col-md-12">*/}
                                    {/*    <textarea name='description' placeholder='description' type="text"*/}
                                    {/*        className="form-control"*/}
                                    {/*        onChange={(event) => handleFormChanges(event, index, 'thingsToCarry')}*/}
                                    {/*        value={form.description}*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    <div className="col-md-2">
                                        <Link to='#' className="btn btn-danger btn-sm"
                                              onClick={() => removeFields(index, 'thingsToCarry')}>Remove</Link>
                                    </div>
                                </div>
                            )
                        })}
                        <Link to='#' className="btn btn-secondary btn-sm mt-2"
                              onClick={() => addFields('thingsToCarry')}>Add More</Link>
                    </div>
                    <hr className='mt-5'/>
                    {/* end of things to carry  dynamic form field */}

                    {/* Testimonials dynamic form field */}
                    {/*<h3 className='mb-1 mt-2'>Testimonials</h3>*/}
                    {/*<div>*/}
                    {/*    {testimonials.map((form, index) => {*/}
                    {/*        return (*/}
                    {/*            <div key={index} className='d-flex flex-column gap-3 my-2 align-items-start'>*/}
                    {/*                <div className="col-md-6">*/}
                    {/*                    <input name='heading' placeholder='name' type="text"*/}
                    {/*                        className="form-control"*/}
                    {/*                        onChange={(event) => handleFormChanges(event, index, 'testimonials')}*/}
                    {/*                        value={form.heading}*/}
                    {/*                    />*/}
                    {/*                </div>*/}
                    {/*                <div className="col-md-6">*/}
                    {/*                    <input name='date' placeholder='date' type="date"*/}
                    {/*                        className="form-control"*/}
                    {/*                        onChange={(event) => handleFormChanges(event, index, 'testimonials')}*/}
                    {/*                        value={form.date}*/}
                    {/*                    />*/}
                    {/*                </div>*/}
                    {/*                <div className="col-md-12">*/}
                    {/*                    <textarea name='description' placeholder='description' type="text"*/}
                    {/*                        className="form-control"*/}
                    {/*                        onChange={(event) => handleFormChanges(event, index, 'testimonials')}*/}
                    {/*                        value={form.description}*/}
                    {/*                    />*/}
                    {/*                </div>*/}
                    {/*                <div className="col-md-12">*/}
                    {/*                    {form?.userimage &&*/}
                    {/*                        <div className="col-4 ">*/}
                    {/*                            <img className='border rounded rounded-2 bg-white p-1 mb-2' width={100} src={form?.userimage} alt="Loading" />*/}
                    {/*                        </div>}*/}
                    {/*                    <input type="file" name='userimage' accept="image/*" className="form-control" onChange={(event) => handleFormChanges(event, index, 'testimonials', 'userimage')} />*/}
                    {/*                </div>*/}
                    {/*                <div className="col-md-2">*/}
                    {/*                    <Link to='#' className="btn btn-danger btn-sm" onClick={() => removeFields(index, 'testimonials')}>Remove</Link>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        )*/}
                    {/*    })}*/}
                    {/*    <Link to='#' className="btn btn-secondary btn-sm mt-2" onClick={() => addFields('testimonials')}>Add More</Link>*/}
                    {/*</div>*/}
                    <hr className='mt-5'/>
                    {/* end of testimonials dynamic form field */}

                    {/* notes dynamic form field */}
                    <h3 className='mb-1 mt-2'>Notes</h3>
                    <div>
                        {notes?.map((form, index) => {
                            return (
                                <div key={index} className='d-flex flex-row gap-3 my-2'>
                                    <div className="col-10">
                                        <textarea name='name' type="text"
                                                  className="form-control" id="notes"
                                                  onChange={(event) => handleFormChanges(event, index, 'notes')}
                                                  value={form.name}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <Link to='#' className="btn btn-danger btn-sm"
                                              onClick={() => removeFields(index, 'notes')}>Remove</Link>
                                    </div>
                                </div>
                            )
                        })}
                        <Link to='#' className="btn btn-secondary btn-sm mt-2" onClick={() => addFields('notes')}>Add
                            More</Link>
                    </div>
                    <hr className='mt-5'/>
                    {/* end of notes dynamic form field */}

                    {/* Inclusion dynamic form field */}
                    <h3 className='mb-1 mt-2'>Inclusion</h3>
                    <div>
                        {inclusion?.map((form, index) => {
                            return (
                                <div key={index} className='d-flex flex-row gap-3 my-2'>
                                    <div className="col-md-10">
                                        <textarea name='name' type="text"
                                                  className="form-control" id="inclusion"
                                                  onChange={(event) => handleFormChanges(event, index, 'inclusion')}
                                                  value={form.name}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <Link to='#' className="btn btn-danger btn-sm"
                                              onClick={() => removeFields(index, 'inclusion')}>Remove</Link>
                                    </div>
                                </div>
                            )
                        })}
                        <Link to='#' className="btn btn-secondary btn-sm mt-2" onClick={() => addFields('inclusion')}>Add
                            More</Link>
                    </div>
                    <hr className='mt-5'/>
                    {/* end of Inclusion dynamic form field */}

                    {/* Exclusion dynamic form field */}
                    <h3 className='mb-1 mt-2'>Exclusion</h3>
                    <div>
                        {exclusion?.map((form, index) => {
                            return (
                                <div key={index} className='d-flex flex-row gap-3 my-2'>
                                    <div className="col-md-10">
                                        <textarea name='name' type="text"
                                                  className="form-control" id="exclusion"
                                                  onChange={(event) => handleFormChanges(event, index, 'exclusion')}
                                                  value={form.name}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <Link to='#' className="btn btn-danger btn-sm"
                                              onClick={() => removeFields(index, 'exclusion')}>Remove</Link>
                                    </div>
                                </div>
                            )
                        })}
                        <Link to='#' className="btn btn-secondary btn-sm mt-2" onClick={() => addFields('exclusion')}>Add
                            More</Link>
                    </div>
                    <hr className='mt-5'/>
                    {/* end of Exclusion dynamic form field */}

                    {/* must carry dynamic form field */}
                    {/*<h3 className='mb-1 mt-2'>Must carry</h3>*/}
                    {/*<div>*/}
                    {/*    {mustCarry.map((form, index) => {*/}
                    {/*        return (*/}
                    {/*            <div key={index} className='d-flex flex-row gap-3 my-2'>*/}
                    {/*                <div className="col-md-10">*/}
                    {/*                    <textarea name='name' type="text"*/}
                    {/*                        className="form-control"*/}
                    {/*                        onChange={(event) => handleFormChanges(event, index, 'mustCarry')}*/}
                    {/*                        value={form.name}*/}
                    {/*                    />*/}
                    {/*                </div>*/}
                    {/*                <div className="col-md-2">*/}
                    {/*                    <Link to='#' className="btn btn-danger btn-sm" onClick={() => removeFields(index, 'mustCarry')}>Remove</Link>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        )*/}
                    {/*    })}*/}
                    {/*    <Link to='#' className="btn btn-secondary btn-sm mt-2" onClick={() => addFields('mustCarry')}>Add More</Link>*/}
                    {/*</div>*/}
                    <hr className='mt-5'/>
                    {/* must carry dynamic form field */}

                    {/* terms and conditions dynamic form field */}
                    <h3 className='mb-1 mt-2'>Terms and conditions</h3>
                    <div>
                        {tnc.map((form, index) => {
                            return (
                                <div key={index} className='d-flex flex-row gap-3 my-2'>
                                    <div className="col-md-10">
                                        <textarea name='name' type="text"
                                                  className="form-control"
                                                  onChange={(event) => handleFormChanges(event, index, 'tnc')}
                                                  value={form.name}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <Link to='#' className="btn btn-danger btn-sm"
                                              onClick={() => removeFields(index, 'tnc')}>Remove</Link>
                                    </div>
                                </div>
                            )
                        })}
                        <Link to='#' className="btn btn-secondary btn-sm mt-2" onClick={() => addFields('tnc')}>Add
                            More</Link>
                    </div>
                    <hr className='mt-5'/>
                    {/* terms and conditions dynamic form field */}

                    {/* Gallery form field */}

                    {/*UNCOMMENT GALLERY*/}
                    {/*<h3 className='mb-1 mt-2'>Gallery</h3>*/}
                    {/*<div className='col-6'>*/}
                    {/*    <input type="file"*/}
                    {/*        className='form-control'*/}
                    {/*        multiple={true}*/}
                    {/*        accept="image/*"*/}
                    {/*        onChange={(event) => { handleOnChange(event, 'tripGallery') }}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    {/*<div className='col-6'>*/}
                    {/*    <button disabled={loading} onClick={onUpload} className='btn btn-secondary btn-sm'>{loading ? 'Loading...' : 'Upload'}</button>*/}
                    {/*</div>*/}
                    {/*<div className='d-flex flex-wrap'>*/}
                    {/*    {*/}
                    {/*        links && links.length > 0 && links?.map(link => {*/}
                    {/*            return (*/}
                    {/*                <div key={link?.publicId} className='p-2'>*/}
                    {/*                    <img width={300} src={link?.url} alt="Loading" />*/}
                    {/*                </div>*/}
                    {/*            )*/}
                    {/*        })*/}
                    {/*    }*/}
                    {/*</div>*/}
                    {/*<hr className='mt-5' />*/}
                    {/* end of Gallery form field */}

                    {/* Accomodation gallary form field */}
                    {/*UNCOMMENT ACCOMODATION*/}
                    {/*<h3 className='mb-1 mt-2'>Accomodation</h3>*/}
                    {/*<div className='col-6'>*/}
                    {/*    <input type="file"*/}
                    {/*        className='form-control'*/}
                    {/*        multiple={true}*/}
                    {/*        accept="image/*"*/}
                    {/*        onChange={(event) => { handleOnChange(event, 'tripAccomodation') }}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    {/*<div className='col-6'>*/}
                    {/*    <button disabled={loading} onClick={onAccomodationUpload} className='btn btn-secondary btn-sm'>{loading ? 'Loading...' : 'Upload'}</button>*/}
                    {/*</div>*/}
                    {/*<div className='d-flex flex-wrap'>*/}
                    {/*    {*/}
                    {/*        accomodationLinks && accomodationLinks.length > 0 && accomodationLinks?.map(link => {*/}
                    {/*            return (*/}
                    {/*                <div key={link?.publicId} className='p-2'>*/}
                    {/*                    <img width={300} src={link?.url} alt="Loading" />*/}
                    {/*                </div>*/}
                    {/*            )*/}
                    {/*        })*/}
                    {/*    }*/}
                    {/*</div>*/}
                    {/*<hr className='mt-5' />*/}
                    {/* end of Accomodation gallary form field */}

                    {/* youtube videos dynamic form field */}
                    {/*<h3 className='mb-1 mt-2'>Youtube Videos</h3>*/}
                    {/*<div>*/}
                    {/*    {videos?.map((form, index) => {*/}
                    {/*        return (*/}
                    {/*            <div key={index} className='d-flex flex-row gap-3 my-2'>*/}
                    {/*                <div className="col-10">*/}
                    {/*                    <input name='url' type="text"*/}
                    {/*                        placeholder='add video url here'*/}
                    {/*                        className="form-control" id="youtubeVideos"*/}
                    {/*                        onChange={(event) => handleFormChanges(event, index, 'youtubeVideos')}*/}
                    {/*                        value={form.url}*/}
                    {/*                    />*/}
                    {/*                </div>*/}
                    {/*                <div className="col-md-2">*/}
                    {/*                    <Link to='#' className="btn btn-danger btn-sm" onClick={() => removeFields(index, 'youtubeVideos')}>Remove</Link>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        )*/}
                    {/*    })}*/}
                    {/*    <Link to='#' className="btn btn-secondary btn-sm mt-2" onClick={() => addFields('youtubeVideos')}>Add More</Link>*/}
                    {/*</div>*/}
                    {/*<div className='d-flex flex-wrap gap-2 col-12'>*/}
                    {/*    {videos[0]?.url !== null && videos?.map(video => {*/}
                    {/*        return <div className="ratio ratio-16x9" style={{ width: '32%' }}>*/}
                    {/*            <iframe src={video.url} title="YouTube video" allowfullscreen></iframe>*/}
                    {/*        </div>*/}
                    {/*    })}*/}
                    {/*</div>*/}

                    {/* {videos.length !== 0? "true" : 'false'} */}
                    <hr className='mt-5'/>
                    {/* end of youtube videos dynamic form field */}

                    <div className="col-12">
                        <button type='submit'
                                className='btn btn-success w-100'>{loading ? 'Loading...' : 'Submit'}</button>
                    </div>
                </form>
            </div>
        </Layout>
    )
}

export default EditTrip
