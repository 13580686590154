// const isProdExtUrl = window.location.host.includes("prodhpadmin-ext.zomans.com");

const isProd = process.env.REACT_APP_ENV == "production"

const env = {
    development: {
        urls: {
            baseUrl: "https://lobster-app-rwlt9.ondigitalocean.app"
        }
    },
    production: {
        urls: {
            baseUrl: "https://lobster-app-rwlt9.ondigitalocean.app"
        }
    },
};

// let apiEndpoint = ''
// if (process.env.REACT_APP_API_ENDPOINT) {
//     apiEndpoint = env[process.env.REACT_APP_API_ENDPOINT];
// } else {
//     apiEndpoint = env[process.env.NODE_ENV];
// }

export default env[process.env.NODE_ENV];